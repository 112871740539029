/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';

import * as React from 'react';

import { CheckIcon, ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';
import * as RadioGroup from '@/lib/@radix-ui/react-radio-group';

const getFilterLabel = (willCall: boolean | null) =>
  willCall === null ? 'Will call' : 'Will call: Hide';

const WillCall: React.FC<{
  setWillCall: (willCall: boolean | null) => void;
  willCall: boolean | null;
}> = ({ setWillCall, willCall }) => {
  const [showChecked, setShowChecked] = useState<boolean>(willCall === null);
  const [willCallLabel, setWillCallLabel] = useState(getFilterLabel(willCall));

  useEffect(() => {
    setWillCallLabel(getFilterLabel(willCall));
    setShowChecked(willCall === null);
  }, [willCall]);

  const handleClose = () =>
    willCall !== showChecked && setWillCall(showChecked ? null : false);

  const handleChange = (filter: string) => {
    switch (filter) {
      case 'show':
        setShowChecked(true);
        break;
      case 'hide':
        setShowChecked(false);
        break;
      default:
        setShowChecked(true);
        break;
    }
  };

  return (
    <div>
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {willCallLabel}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            <RadioGroup.Root
              className="RadioButtonGroupContainer"
              defaultValue={showChecked ? 'show' : 'hide'}
              onValueChange={handleChange}
            >
              <div className="d-flex dropdown-option">
                <RadioGroup.Item
                  className="RadioButtonGroupItem"
                  value="show"
                  id="show-radio"
                >
                  <RadioGroup.Indicator className="RadioButtonIndicator">
                    <CheckIcon />
                  </RadioGroup.Indicator>
                </RadioGroup.Item>
                <label
                  className={`Label flex-1 ${showChecked ? 'checked' : ''}`}
                  htmlFor="show-radio"
                >
                  Show
                </label>
              </div>
              <div className="d-flex dropdown-option">
                <RadioGroup.Item
                  className="RadioButtonGroupItem"
                  value="hide"
                  id="hide-radio"
                >
                  <RadioGroup.Indicator className="RadioButtonIndicator">
                    <CheckIcon />
                  </RadioGroup.Indicator>
                </RadioGroup.Item>
                <label
                  className={`Label flex-1 ${showChecked ? '' : 'checked'}`}
                  htmlFor="hide-radio"
                >
                  Hide
                </label>
              </div>
            </RadioGroup.Root>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default WillCall;
