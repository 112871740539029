import type { RootState } from '../store';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

// eslint-disable-next-line import/prefer-default-export
export const roundtripBaseApiV3 = createApi({
  reducerPath: 'roundtripBaseApiV3',
  baseQuery: fetchBaseQuery({
    jsonReplacer(this: any, _key: string, value: any) {
      if (value && typeof value === 'object') {
        const replacement: { [key: string]: any } = {};

        // eslint-disable-next-line no-restricted-syntax
        for (const k in value) {
          if (Object.hasOwnProperty.call(value, k)) {
            replacement[
              k.replace(/[A-Z]/g, (match) => `_${match.toLowerCase()}`)
            ] = value[k];
          }
        }

        return replacement;
      }

      return value;
    },
    baseUrl: '/api/v3/',
    prepareHeaders: (headers, { getState }) => {
      const { token } = (getState() as RootState).auth;

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      if (!headers.has('Content-Type')) {
        headers.set('Content-Type', 'application/json');
      }

      headers.set('Accept', 'application/json');

      return headers;
    },
  }),
  endpoints: () => ({}),
  tagTypes: ['UserPrefs'],
});
