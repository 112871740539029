import { useEffect } from 'react';

import type { GlobalSSRData } from '@/types';

import { useDispatch } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { pushFlashMessage } from '../../store/flashMessagesSlice';
import FlashMessages from '../FlashMessages';

const Container = () => {
  // eslint-disable-next-line no-underscore-dangle
  const { flash } = (window as GlobalSSRData)._unsafeSSRData.global;
  const dispatch = useDispatch();

  useEffect(() => {
    if (flash?.length > 0) {
      flash.forEach(([type, text]) => {
        // Rails passes in `['alert', nil]` when flash list is empty and `[true, 'timeout']` when sessions expire.
        if (
          !type ||
          !text ||
          typeof type !== 'string' ||
          typeof text !== 'string'
        ) {
          return;
        }

        dispatch(
          pushFlashMessage({
            text,
            type,
          }),
        );
      });
    }
  }, [flash]);

  return (
    <div
      className="wrapper mt-6 pb-10 -wide"
      id="main-community-container"
      role="main"
    >
      <FlashMessages />

      <Outlet />
    </div>
  );
};

export default Container;
