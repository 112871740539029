/* eslint-disable import/prefer-default-export */

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';
import { Ride } from '@/types';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRoundtripDetails: build.query({
      query: (tripId: number) => {
        return {
          url: `/rides`,
          method: 'GET',
          params: {
            trip_id: tripId,
          },
        };
      },
      transformResponse: (response: {rides: Ride[]}) =>
        keysToCamel(response) as {rides: Ride[]},
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetRoundtripDetailsQuery, useLazyGetRoundtripDetailsQuery } =
  extendedApi;
