/* eslint-disable import/prefer-default-export */
import type { SubmitRideResponse } from '../../types';

import { roundtripBaseApiV3 } from '../../lib/rtk-query';

const createRideResponseBody = (
  params: Partial<{
    additionalInfo: string;
    declinedAt: Date;
    eta: Date;
    reason: string;
    rideId: number;
  }>,
) => {
  const body: Record<string, SubmitRideResponse> = {};
  if (params.eta) body.eta = params.eta;
  if (params.declinedAt) body.declinedAt = params.declinedAt;
  if (params.reason) body.reason = params.reason;
  if (params.additionalInfo) body.additionalInfo = params.additionalInfo;
  return body;
};

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    submitRideResponse: build.mutation<void, SubmitRideResponse>({
      query: (params) => ({
        url: `rides/${params.rideId}/ride_eta_responses`,
        method: 'POST',
        body: { rideEtaResponse: createRideResponseBody(params) },
      }),
    }),
  }),
});

export const { useSubmitRideResponseMutation } = extendedApi;
