import * as React from 'react';

const ClearFilters: React.FC<{
  children?: React.ReactNode;
  className?: string;
  reset: () => void;
  visible?: boolean;
}> = ({ reset, visible = true, className = '', children }) => {
  if (!visible) {
    return null;
  }

  return (
    <button
      type="button"
      className={`filter-label ${className}`}
      onClick={reset}
      tabIndex={0}
    >
      Clear filters
      {children}
    </button>
  );
};

export default ClearFilters;
