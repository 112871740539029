import * as React from 'react';

import './Spinner.scss';

type LoaderSize = 'extra-large' | 'small' | 'medium' | 'large';

interface Props {
  className?: string;
  size?: LoaderSize;
}

const Loader: React.FC<React.HTMLAttributes<HTMLDivElement> & Props> = ({
  className = 'mt-32',
  size = 'extra-large',
}) => {
  return <div className={`loader ${className} ${size}`} alt="Loader" />;
};

export default Loader;
