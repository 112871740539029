import { useMemo } from 'react';

import type { CellComponent } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';
import RideStatusBadge from '@/features/RideStatusBadge';
import {
  CC_RIDE_STATUS_BADGE_TEXT,
  CC_STYLES,
  ICONS,
} from '@/features/RideStatusBadge/types';
import { AUTH_DISPLAY_NAMES } from '@/types';

import { CheckingAvailability, Claimed } from './tooltips';

/* eslint-disable camelcase */
export const CC_TOOLTIPS = {
  canceled: null,
  completed: null,
  brokered: null,
  on_hold: null,
  will_call: null,
  incomplete: null,
  requested: null,
  finding_driver: null,
  claimed: Claimed,
  finding_providers: null,
  checking_availability: CheckingAvailability,
  at_pickup: null,
  to_pickup: null,
  at_destination: null,
  to_destination: null,
} as const;
/* eslint-enable camelcase */

const RideStatusBadgeCell: CellComponent = ({ ride, href }) => {
  const { name } = useMemo(
    () => ({
      name: ride.riderFullName.split(' ').join('-').toLowerCase(),
    }),
    [ride],
  );

  const style = CC_STYLES[ride.statusBadge];
  const Icon = ICONS[ride.statusBadge];
  const SubIcon = null;
  const ToolTip = CC_TOOLTIPS[ride.statusBadge];

  const subtext = ride.authStatus ? AUTH_DISPLAY_NAMES[ride.authStatus] : null;

  return (
    <Cell
      label="Status"
      tdClass="time-remaining -overflow"
      href={typeof href === 'function' ? href(ride) : href}
      anchorClass="py-8"
    >
      <RideStatusBadge.Root color={style}>
        <RideStatusBadge.Content id={`${name}-bid-status-message`}>
          {Icon && <Icon />}
          {CC_RIDE_STATUS_BADGE_TEXT[ride.statusBadge]}
        </RideStatusBadge.Content>

        {ToolTip && <ToolTip ride={ride} />}

        {subtext && (
          <RideStatusBadge.Subtext>
            {/* {SubIcon && <SubIcon />} {subtext} */}
            {subtext}
          </RideStatusBadge.Subtext>
        )}
      </RideStatusBadge.Root>
    </Cell>
  );
};

export default RideStatusBadgeCell;
