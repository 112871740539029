import type { CellComponent } from '@/features/RidesTable/types';

import TimeCell from './TimeCell';

const PickupTimeCell: CellComponent = ({ ride, href }) => (
  <TimeCell
    time={
      ride.startTimeAwardedBid && ride.startTimeAwardedBid.length > 0
        ? ride.startTimeAwardedBid
        : ride.startTime
    }
    href={typeof href === 'function' ? href(ride) : href}
    willCall={ride.willCall}
    label="Pickup time"
  />
);

export default PickupTimeCell;
