import { useEffect, useState } from 'react';
import * as React from 'react';

import CheckboxItem from '@/features/RidesTable/components/Filters/CheckboxItem';

import './main.scss';

import { useDispatch, useSelector } from 'react-redux';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import {
  selectAllRidesSelected,
  setAllRidesSelected,
} from '@/features/RidesTable/store/selectedRidesSlice';

const BulkActionIcon: React.FC = () => {
  const dispatch = useDispatch();
  const allRidesSelected = useSelector(selectAllRidesSelected);

  const [isChecked, setIsChecked] = useState(allRidesSelected);

  const { selectAllRides, deselectAllRides } = useTableContext();

  const handleChecked = () => {
    setIsChecked((prev) => {
      const newValue = !prev;
      if (newValue) {
        selectAllRides();
        dispatch(setAllRidesSelected(true));
      } else {
        deselectAllRides();
        dispatch(setAllRidesSelected(false));
      }
      return newValue;
    });
  };

  useEffect(() => {
    setIsChecked(allRidesSelected);
  }, [allRidesSelected]);

  return (
    <div className="bulk-action">
      <CheckboxItem
        text=""
        checked={isChecked}
        onCheckedChange={() => handleChecked()}
      />
    </div>
  );
};

export default BulkActionIcon;
