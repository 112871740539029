import type { CellComponent } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';

const PatientCell: CellComponent = ({ ride, href }) => {
  const name = ride.riderFullName;
  const patientNormalizedName = name.split(' ').join('-').toLowerCase();

  return (
    <Cell
      label="Ride Name"
      tdClass="patient"
      href={typeof href === 'function' ? href(ride) : href}
      id={patientNormalizedName}
    >
      <strong className="fs-block">{name}</strong>
      <br />
    </Cell>
  );
};

export default PatientCell;
