import type { CellComponent } from '@/features/RidesTable/types';

import Cell from '@/features/RidesTable/components/Table/Cell';

const RideBookerCell: CellComponent = ({ ride, href }) => (
  <Cell
    label="Ride Name"
    tdClass="patient"
    href={typeof href === 'function' ? href(ride) : href}
    id={ride.rideBookerName}
  >
    {ride.rideBookerName}
  </Cell>
);

export default RideBookerCell;
