import { useContext } from 'react';
import * as React from 'react';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';

import './PerPageDropdown.scss';

import { TableContext } from '@/features/RidesTable/providers/TableProvider';

interface PerPageDropdownProps {
  rowsPerPage: number;
}
const perPageOptions = [10, 20, 50, 100];

const PerPageDropdown: React.FC<PerPageDropdownProps> = ({ rowsPerPage }) => {
  const { onChangeRowsPerPage } = useContext(TableContext);

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <span className="filter-label">
          {`${rowsPerPage} per page`}
          <ChevronDownIcon />
        </span>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="popover-content per-page-options"
          align="start"
        >
          {perPageOptions.map((option) => (
            <button
              className="per-page-option"
              key={option}
              onClick={() => onChangeRowsPerPage(option)}
              type="button"
            >
              {option}
            </button>
          ))}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default PerPageDropdown;
