/* eslint-disable import/prefer-default-export */
import type { DeleteRide } from '../../types';

import { roundtripBaseApiV3 } from '../../lib/rtk-query';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    deleteRide: build.mutation<void, DeleteRide>({
      query: ({ body, rideId }) => ({
        url: `rides/${rideId}`,
        method: 'DELETE',
        body: { ride: body },
      }),
    }),
  }),
});

export const { useDeleteRideMutation } = extendedApi;
