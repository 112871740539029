import * as React from 'react';

import { error as logError } from '@/lib/@datadog/browser-logs';
import isDev from '@/utils/isDev';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, type: error.name };
  }

  // Capture and send event to Sentry
  componentDidCatch(e, errorInfo) {
    // Sentry.withScope((scope) => {
    //   scope.setExtras(errorInfo);
    //   const eventId = Sentry.captureException(error);
    //   this.setState({ eventId });
    // });

    logError(e, errorInfo);
  }

  render() {
    const { hasError, type } = this.state;

    if (!hasError || hasError === undefined) {
      return this.props.children;
    }

    // switch (type) {
    //   default:
    //     return
    // }

    if (!isDev) {
      window.location.pathname = '/500';
    }

    return;
  }
}

export default ErrorBoundary;
