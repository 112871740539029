/* eslint-disable jsx-a11y/label-has-associated-control */
import * as React from 'react';

import Check from '@assets/images/Check.svg';
import { useDispatch } from 'react-redux';

import { useCompleteRideMutation } from '@/api';
import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushFlashMessage } from '@/store/flashMessagesSlice';
import { pushToastNotification } from '@/store/toastNotificationsSlice';

const BulkCompleteOption: React.FC<{ selectedRides: number[] }> = ({
  selectedRides,
}) => {
  const dispatch = useDispatch();
  const [completeRide] = useCompleteRideMutation();
  const { removeRideById, deselectAllRides, rides } = useTableContext();

  const validateRideStartTime = () => {
    return selectedRides.every((rideId: number) => {
      const ride = rides.find((iteratedRide) => iteratedRide.id === rideId);
      return ride && ride.startTime < new Date().toISOString();
    });
  };

  const handleCompleteRide = async () => {
    try {
      const completeRidePromises = selectedRides.map((rideId) =>
        completeRide(rideId)
          .unwrap()
          .then(() => {
            removeRideById(rideId);
          })
          .catch(() => {
            dispatch(
              pushFlashMessage({
                text: `Sorry, ride ${rideId} cannot be completed at the moment. Please try again in a few minutes.`,
                type: 'error',
              }),
            );
          }),
      );

      await Promise.all(completeRidePromises);

      dispatch(
        pushToastNotification({
          text: `${rides.length} rides successfully completed.`,
          action: 'success',
        }),
      );
      deselectAllRides();
    } catch (error) {
      dispatch(
        pushFlashMessage({
          text: 'An error occurred while completing rides. Please try again in a few minutes.',
          type: 'error',
        }),
      );
    }
  };

  return validateRideStartTime() ? (
    <button
      type="button"
      className="filter-label"
      onClick={() => handleCompleteRide()}
    >
      Complete rides
      <Check />
    </button>
  ) : null;
};

export default BulkCompleteOption;
