/* eslint-disable react/jsx-props-no-spreading */
import { Provider as ReduxProvider } from 'react-redux';

import ErrorBoundary from '@/components/ErrorBoundary';
import withLDProvider from '@/lib/launchdarkly-react-client-sdk';

import { AuthProvider } from '../contexts/AuthProvider';
import ToastProvider  from '../contexts/ToastProvider'
import setupStore from '../store';

const store = setupStore();

/**
 * This component is wrapped around all components (outside of the book trip flow)
 * rendered from HAML with `react_component`. Typically components fall under the same
 * render tree starting at `App.js`. This file is designed to act as a parent component
 * providing the necessary Providers & Contexts such as Redux.
 */
const ComponentWrapper = (Component) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const App = () => (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <AuthProvider>
          <ToastProvider>
            <Component />
          </ToastProvider>
        </AuthProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );

  const LDProvider = withLDProvider(
    window._unsafeSSRData.global.currentUser,
    window._unsafeSSRData.global.isDriver,
    window._unsafeSSRData.global.transportationCompanyId,
  )(App);

  return <LDProvider />;
};

export default ComponentWrapper;
