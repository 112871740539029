/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from 'react';

import type { ExcludibleColumn, ROLES, UserPreferencesPages } from '@/types';

import { LayoutIcon } from '@radix-ui/react-icons';

import {
  userPreferencesDefaultState,
  useUpdateUserPrefsMutation,
} from '@/api/rides/getUserPreferences';
import { useAuth } from '@/contexts/AuthProvider';
import useUserPreferences from '@/hooks/useUserPreferences';
import { error } from '@/lib/@datadog/browser-logs';
import * as Popover from '@/lib/@radix-ui/react-popover';

import CheckboxItem from './CheckboxItem';

const EditColumns = <T extends keyof typeof ROLES>({
  columns,
  page,
  userRole,
}: {
  columns: { key: ExcludibleColumn; text: string }[];
  page: UserPreferencesPages[T];
  userRole: T;
}) => {
  const { currentUser } = useAuth();

  const { data = userPreferencesDefaultState } = useUserPreferences();

  const columnsToExcludeFromRendering = Object.values(
    data?.userPreference?.dashboardColumnPreferences?.[userRole]?.[page]
      ?.columnsToExcludeFromRendering || {},
  ) as ExcludibleColumn[];

  /**
   * Checkboxes are "checked" when they do not exist within this array.
   */
  const [excludedColumns, setExcludedColumns] = useState<ExcludibleColumn[]>(
    [],
  );

  const selectedPreferences = data?.userPreference?.dashboardColumnPreferences;

  useEffect(() => {
    setExcludedColumns(columnsToExcludeFromRendering);
  }, [data, setExcludedColumns]);

  function updateColumnsToExclude(columnName: ExcludibleColumn[]) {
    const index = excludedColumns.indexOf(columnName[0]);

    if (index !== -1) {
      const updatedPreferences = [...excludedColumns];
      updatedPreferences.splice(index, 1);
      setExcludedColumns(updatedPreferences);
    } else {
      setExcludedColumns([...excludedColumns, columnName[0]]);
    }
  }

  const [updateUserPrefsMutation] = useUpdateUserPrefsMutation();

  const handleClose = () => {
    // Do not update db if values have not changed.
    if (columnsToExcludeFromRendering === excludedColumns) {
      return;
    }

    // Merge deeply nested array
    const prefs = {
      dashboardColumnPreferences: {
        ...selectedPreferences,
        [userRole]: {
          ...selectedPreferences?.[userRole],
          [page]: {
            ...selectedPreferences?.[userRole]?.[page],
            columnsToExcludeFromRendering: excludedColumns,
          },
        },
      },
    };

    updateUserPrefsMutation({
      userId: currentUser.id,
      userPreference: prefs,
    }).catch((err: string) => error(err));
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label t-grey-50 px-2">
            Edit columns
            <LayoutIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="end"
            onCloseAutoFocus={handleClose}
          >
            {columns.map(({ text, key }) => (
              <CheckboxItem
                text={text}
                checked={!excludedColumns.includes(key)}
                onCheckedChange={() => updateColumnsToExclude([key])}
                key={key}
              />
            ))}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default EditColumns;
