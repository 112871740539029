import { useEffect, useState } from 'react';

import { ChevronDownIcon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from 'react-redux';

import { useGetTransportationCompanyQuery } from '@/api';
import { useAuth } from '@/contexts/AuthProvider';
import * as Popover from '@/lib/@radix-ui/react-popover';
import { selectToken } from '@/store/authSlice';

import {
  selectIsInit,
  selectRadius,
  setRadius,
} from '../../store/ridesFilterSlice';

const PROXIMITY_LABEL = 'Proximity';

const Distance = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const { transportationCompanyId }: { transportationCompanyId: number } =
    currentUser;
  const token = useSelector(selectToken);
  const { data } = useGetTransportationCompanyQuery(transportationCompanyId, {
    skip: !token,
  });
  let zipcode;

  if (data?.transportationCompany) {
    zipcode = data?.transportationCompany?.zipcode;
  }

  const radius = useSelector(selectRadius);
  const isInit = useSelector(selectIsInit);
  const [distanceInput, setDistanceInput] = useState(radius);

  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  const getFilterLabel = () => {
    if (distanceInput === null) {
      return PROXIMITY_LABEL;
    }

    return `${PROXIMITY_LABEL}: ${distanceInput} miles`;
  };

  const [distanceLabel, setDistanceLabel] = useState(getFilterLabel());

  useEffect(() => {
    if (isInit) {
      setDistanceInput(null);
      setDistanceLabel(PROXIMITY_LABEL);
    }
  }, [isInit]);

  const onClose = () => {
    dispatch(setRadius(distanceInput));
    setDistanceLabel(getFilterLabel());
  };

  const onChange = (e) => {
    setDistanceInput(e.target.value);
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {distanceLabel}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content p-3"
            align="start"
            onCloseAutoFocus={onClose}
          >
            <div className="d-flex flex-column">
              <label className="popover-title">Max. distance to pickup</label>
              <div className={`styled-input ${isFocused ? 'focused' : ''}`}>
                <input
                  type="number"
                  className="styled-input__field pl-2"
                  value={distanceInput}
                  onChange={onChange}
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      dispatch(setRadius(distanceInput));
                      setDistanceLabel(getFilterLabel());
                    }
                  }}
                />
                <div className="px-2 back-label">miles</div>
              </div>
              {zipcode && (
                <div className="mt-2 t-sm t-grey-50">
                  Based on your zip code: {zipcode}
                </div>
              )}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default Distance;
