import type { CellComponent } from '@/features/RidesTable/types';

import LocationCell from './LocationCell';

const PickupLocationCell: CellComponent = ({ ride, href }) => {
  const { pickupLocation } = ride;
  const location = pickupLocation;

  return (
    <LocationCell
      location={location}
      label="Pickup"
      href={typeof href === 'function' ? href(ride) : href}
    />
  );
};

export default PickupLocationCell;
