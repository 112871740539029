import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import type { AppDispatch } from '@/store';
import type { GlobalSSRData, User } from '@/types';

import { useDispatch } from 'react-redux';

import { datadogLogs } from '@/lib/@datadog/browser-logs';
import { setCredentials } from '@/store/authSlice';

type AuthContextData = {
  authError?: Error;
  currentUser: User;
  isAuthenticated: boolean;
  loading: boolean;
  signIn(): Promise<void>;
  signOut(): Promise<void>;
  signUp(): Promise<void>;
};

type ErrorHandler = (callback: () => Promise<void>) => () => Promise<void>;

interface Props {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: Props) => {
  const [authError, setAuthError] = useState<Error | undefined>();
  const dispatch = useDispatch<AppDispatch>();

  // eslint-disable-next-line no-underscore-dangle
  const { token, currentUser } = (window as GlobalSSRData)._unsafeSSRData
    .global;

  useEffect(() => {
    if (token) {
      dispatch(setCredentials({ token: token.toString() }));
    }
  }, []);

  useEffect(() => {
    if (currentUser?.id) {
      datadogLogs.setUser({
        id: `${currentUser.id}`,
      });

      return;
    }

    const user = datadogLogs.getUser();
    if (user?.id) {
      datadogLogs.clearUser();
    }
  }, [currentUser]);

  const reset = () => {};
  const signOut = async () => {};
  const signIn = async () => {};
  const signUp = async () => {};

  const errorHandler: ErrorHandler = (callback) => async () => {
    try {
      await callback();
    } catch (e) {
      setAuthError(e as Error);
    }
  };

  const final: AuthContextData = useMemo(
    () => ({
      currentUser: currentUser || ({} as User),
      authError,
      isAuthenticated: Boolean(currentUser),
      loading: false,
      signIn: errorHandler(signIn),
      signOut: errorHandler(signOut),
      signUp: errorHandler(signUp),
    }),
    [currentUser],
  );

  return <AuthContext.Provider value={final}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

export { AuthContext, AuthProvider, useAuth };
