import BulkActionIcon from '@/components/RidesTable/BulkActionCell/BulkActionIcon';

import './styles.scss';

interface ColumnHeader {
  label?: string;
  sortKey?: string;
}

const ActionHeader = ({ sortKey, label }: ColumnHeader) => {
  return (
    <th key={sortKey} className="action-header-label">
      <div
        className="d-inline-flex flex-items-center"
        style={{ gap: '0.125rem' }}
      >
        {label === 'Bulk Action' && <BulkActionIcon />}
      </div>
    </th>
  );
};

export default ActionHeader;
