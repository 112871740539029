/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';

import type { DateRange, SelectRangeEventHandler } from 'react-day-picker';

import { DayPicker } from 'react-day-picker';

import * as Popover from '@/lib/@radix-ui/react-popover';
import dayjs from '@/lib/dayjs';

import './DatePicker.scss';

import type { Entries } from '@/types';

interface Props {
  endDate: dayjs.Dayjs;
  setEndDate: (day: dayjs.Dayjs) => void;
  setStartDate: (day: dayjs.Dayjs) => void;
  startDate: dayjs.Dayjs;
}

type Range = [dayjs.Dayjs, dayjs.Dayjs];

/**
 * Note: Technically, this logic will be wrong if this module loads on 11:59pm and
 * the date picker is used at 12:00am. This can be fixed by moving this code block
 * into the component. However, I didn't think initialized this constant, and its
 * values, every render was worth the trade off.
 */
const today = dayjs().startOf('day');
const startOfToday = today.startOf('day');
const endOfToday = today.endOf('day');
const DATE_PICKER_OPTIONS: Record<string, Range> = {
  Today: [startOfToday, endOfToday],
  Yesterday: [startOfToday.subtract(1, 'day'), endOfToday.subtract(1, 'day')],
  'Next 7 Days': [startOfToday, endOfToday.add(7, 'day')],
  'Next 14 Days': [startOfToday, endOfToday.add(14, 'day')],
  'Past 7 Days': [startOfToday.subtract(7, 'day'), endOfToday],
  'Past 14 Days': [startOfToday.subtract(14, 'day'), endOfToday],
};

const DatePicker = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}: Props) => {
  const defaultRange = {
    from: startDate.toDate(),
    to: endDate.toDate(),
  };

  /**
   * Set to `undefined` if the user clicks the current selected start date causing the range to reset.
   */
  const [range, setRange] = useState<DateRange | undefined>(defaultRange);

  /**
   * Resets range picker values if user previously closed popover with an undefined state.
   */
  const resetNullState = (open: boolean) => {
    if (open && !range) {
      setRange(defaultRange);
    }
  };

  /**
   * Do not update selected dates until both dates have a value after a reset state.
   */
  useEffect(() => {
    if (range?.from && range?.to) {
      setStartDate(dayjs(range.from).startOf('day'));
      setEndDate(dayjs(range.to).endOf('day'));
    }
  }, [range?.to, range?.from]);

  const isActive = ([start, end]: Range) =>
    start.diff(startDate, 'day') === 0 && end.diff(endDate, 'day') === 0;

  const onClick = ([start, end]: Range) => {
    setRange({
      from: start.toDate(),
      to: end.toDate(),
    });

    setStartDate(start);
    setEndDate(end);
  };

  const onSelect: SelectRangeEventHandler = (selection?: DateRange) => {
    setRange(selection);
  };

  const inputDisplayValue = `${startDate.format('MM/DD')} - ${endDate.format('MM/DD/YYYY')}`;

  return (
    <div className="field my-1 my-sm-0">
      <Popover.Root onOpenChange={resetNullState}>
        <Popover.Trigger asChild>
          <input
            size={12}
            type="text"
            placeholder={dayjs().format()}
            value={inputDisplayValue}
            className="input -calendar -left-aligned -mid"
          />
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content daypicker p-0"
            align="start"
          >
            <div className="d-block">
              <div className="date-ranges">
                {(
                  Object.entries(DATE_PICKER_OPTIONS) as Entries<
                    typeof DATE_PICKER_OPTIONS
                  >
                ).map(([key, value]) => {
                  return (
                    <span
                      className={
                        isActive(value)
                          ? 'date-range-option is-active'
                          : 'date-range-option'
                      }
                      key={key}
                      onClick={() => onClick(value)}
                    >
                      {key}
                    </span>
                  );
                })}
              </div>
            </div>

            <DayPicker
              initialFocus={false}
              mode="range"
              defaultMonth={today.toDate()}
              selected={range}
              onSelect={onSelect}
              fixedWeeks
              numberOfMonths={2}
            />

            <Popover.Arrow className="popover-arrow" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default DatePicker;
