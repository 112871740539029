/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';

import * as React from 'react';

import Release from '@assets/images/Release.svg';

import BulkReleaseModal from './BulkReleaseModal';

const BulkReleaseOption: React.FC<{ selectedRides: number[] }> = ({
  selectedRides,
}) => {
  const [showReleaseModal, setShowReleaseModal] = useState(false);

  return (
    <div className="">
      <span className="filter-label" onClick={() => setShowReleaseModal(true)}>
        Release rides
        <Release />
      </span>
      <BulkReleaseModal
        hide={() => setShowReleaseModal(false)}
        show={showReleaseModal}
        rideIds={selectedRides}
      />
    </div>
  );
};

export default BulkReleaseOption;
