import { useState } from 'react';
import * as React from 'react';

import { useDispatch } from 'react-redux';

import { useSubmitRideResponseMutation } from '@/api';
import { pushFlashMessage } from '@/store/flashMessagesSlice';

import './main.scss';

import { useTableContext } from '@/features/RidesTable/providers/TableProvider';
import { pushToastNotification } from '@/store/toastNotificationsSlice';
import { DECLINE_REASONS_TEXT } from '@/types';

import Modal from './Modal';

const DeclineModal: React.FC<{
  hide: () => void;
  rideId: number;
  show: boolean;
}> = ({ rideId, hide, show }) => {
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [selectedReason, setSelectedReason] = useState('');

  const { removeRideById } = useTableContext();

  const dispatch = useDispatch();

  const [submitRideResponse] = useSubmitRideResponseMutation();

  const handleReasonChange = (event: React.ChangeEvent<HTMLSelectElement>) =>
    setSelectedReason(event?.target?.value);
  const handleAdditionalInfoChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => setAdditionalInfo(event.target.value);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      declinedAt: new Date(),
      rideId,
      reason: selectedReason,
      additionalInfo,
    };

    submitRideResponse(body)
      .unwrap()
      .then(() => {
        removeRideById(rideId);
        hide();
        dispatch(
          pushToastNotification({
            text: 'Ride successfully declined.',
            rideId,
            action: 'view ride',
          }),
        );
      })
      .catch(() => {
        dispatch(
          pushFlashMessage({
            text: 'Sorry, this ride cannot be canceled at the moment. Please try again in a few minutes.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <Modal
      open={show}
      hide={hide}
      title="Decline ride"
      subtitle="Are you sure? Any ETA is better than no ETA."
    >
      <form
        className="form p-0"
        id="cancel-ride"
        acceptCharset="UTF-8"
        method="delete"
        onSubmit={handleSubmit}
      >
        <div className="field mt-1">
          <label htmlFor="reason" className="label">
            Decline reason <span className="required">*</span>
          </label>
          <div className="dropdown-wrapper">
            <select
              data-test="reason"
              id="reason"
              name="reason"
              value={selectedReason}
              onChange={handleReasonChange}
              className="dropdown"
              required
            >
              <option value="">Select a reason</option>
              {Object.entries(DECLINE_REASONS_TEXT).map(([key, text]) => (
                <option key={key} value={key}>
                  {text}
                </option>
              ))}
            </select>
          </div>

          <label
            htmlFor="additional_information"
            className="label"
            style={{ marginTop: '20px' }}
          >
            Additional Information
          </label>
          <textarea
            id="additional_info"
            name="additional_info"
            value={additionalInfo}
            onChange={handleAdditionalInfoChange}
            className="input -tall"
          />
        </div>

        <div className="alert-dialog-actions">
          <button
            type="button"
            className="button py-2 -inverted-blue"
            onClick={hide}
          >
            Nevermind
          </button>

          <button
            data-test="cancel-ride-submit-btn"
            type="submit"
            className="button py-2 -cancel"
          >
            Decline Ride
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default DeclineModal;
