import type { CellComponent } from '@/features/RidesTable/types';

import TimeCell from './TimeCell';

const CompletedAtCell: CellComponent = ({ ride, href }) => (
  <TimeCell
    time={ride.completedAt as string}
    href={typeof href === 'function' ? href(ride) : href}
    willCall={false}
    label="Completed time"
  />
);

export default CompletedAtCell;
