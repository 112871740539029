import * as React from 'react';

const Cell: React.FC<{
  anchorClass?: string;
  children: React.ReactNode;
  href: string;
  id?: string;
  label: string;
  tdClass: string;
}> = ({ children, tdClass, label, href, anchorClass = 'table-link', id }) => (
  <td className={tdClass} data-label={label} data-testid={id} data-test={id}>
    <a href={href} className={anchorClass}>
      {children}
    </a>
  </td>
);

export default Cell;
