/* eslint-disable import/prefer-default-export */

import type { Ride } from '../../types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRide: build.query({
      query: (rideId: number) => {
        return {
          url: `rides_list/${rideId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: Ride }) => {
        return (keysToCamel(response) as { ride: Ride }).ride;
      },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as { data: { errors: string[] } }, // TODO: Type error response
    }),
  }),
});

export const { useLazyGetRideQuery } = extendedApi;
