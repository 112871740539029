/* eslint-disable import/prefer-default-export */

import type { Trip } from '../../types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getTrip: build.query({
      query: (tripId: number) => {
        return {
          url: `trips/${tripId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: Trip }) =>
        (keysToCamel(response) as { data: Trip }).trip,
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
  }),
});

export const { useGetTripQuery, useLazyGetTripQuery } = extendedApi;
