import { useContext } from 'react';

import SortDownIcon from '../../../../../assets/images/icon-sort-down.svg';
import SortUpIcon from '../../../../../assets/images/icon-sort-up.svg';
import SortIcon from '../../../../../assets/images/icon-sort.svg';

import './styles.scss';

import { TableContext } from '../../providers/TableProvider';

interface ColumnHeader {
  label?: string;
  sortKey?: string;
}

const ASC = 'ASC';
const DESC = 'DESC';

const Header = ({ sortKey, label }: ColumnHeader) => {
  const {
    sortField: currField,
    sortType: currOrder,
    onChangeSortField,
    onChangeSortType,
  } = useContext(TableContext);
  // On initial render, currField is undefined at first so we need to handle that edge case
  const isActive =
    currField === sortKey && Boolean(currField) && Boolean(currOrder);

  const onClick = () => {
    if (isActive) {
      onChangeSortType(currOrder === DESC ? ASC : null);

      return;
    }

    onChangeSortType(DESC);
    onChangeSortField(sortKey);
  };

  let Icon = SortIcon;
  if (isActive && currOrder === ASC) {
    Icon = SortDownIcon;
  }
  if (isActive && currOrder === DESC) {
    Icon = SortUpIcon;
  }

  return (
    <th
      onClick={onClick}
      key={sortKey}
      className={`header-label ${isActive ? 'active' : ''}`}
    >
      <div
        className="d-inline-flex flex-items-center"
        style={{ gap: '0.125rem' }}
      >
        {label}
        <Icon />
      </div>
    </th>
  );
};

export default Header;
