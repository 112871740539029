import { useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import type { AuthStatus, Entries } from '@/types';

import { ChevronDownIcon } from '@radix-ui/react-icons';

import * as Popover from '@/lib/@radix-ui/react-popover';
import { AUTH_DISPLAY_NAMES, RIDE_AUTH_STATUS } from '@/types';

import CheckboxItem from './CheckboxItem';

const authList = Object.entries(RIDE_AUTH_STATUS) as Entries<
  typeof RIDE_AUTH_STATUS
>;

const filterLabel = 'Auth. status';

const getFilterLabel = (selected: AuthStatus[]) => {
  if (selected.length === 0) {
    return filterLabel;
  }

  if (selected.length === 1) {
    return `${filterLabel}: ${AUTH_DISPLAY_NAMES[selected[0]]}`;
  }

  return `${filterLabel}: Multiple`;
};

const arrayEquals = (a: AuthStatus[], b: AuthStatus[]) =>
  a.length === b.length && a.every((val, index) => val === b[index]);

const AuthorizationStatus: React.FC<{
  setStatuses: (statuses: AuthStatus[]) => void;
  statuses: AuthStatus[];
}> = ({ statuses, setStatuses }) => {
  const [selected, setSelected] = useState(statuses);

  const label = useMemo(() => getFilterLabel(statuses), [statuses]);

  useEffect(() => setSelected(statuses), [statuses]);

  const handleClose = () =>
    !arrayEquals(statuses, selected) && setStatuses(selected);

  const handleCheck = (status: AuthStatus) => {
    const statusPresent = selected.includes(status);

    if (statusPresent) {
      setSelected(selected.filter((statuss) => status !== statuss));
    } else {
      setSelected([...selected, status]);
    }
  };

  return (
    <div className="">
      <Popover.Root>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {label}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="popover-content"
            align="start"
            onCloseAutoFocus={handleClose}
          >
            {authList.map(([key, value]) => {
              return (
                <CheckboxItem
                  key={key}
                  text={AUTH_DISPLAY_NAMES[value]}
                  checked={selected.includes(value)}
                  onCheckedChange={() => handleCheck(value)}
                />
              );
            })}
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default AuthorizationStatus;
