/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import Flash from '@root/components/Flash';
import { useDispatch, useSelector } from 'react-redux';

import { popFlashMessage } from '../store/flashMessagesSlice';

const FlashMessages = () => {
  const { messages } = useSelector((state) => state.flashMessages);
  const [flashMessageList, setFlashMessageList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (messages instanceof Array) {
      setFlashMessageList(messages);
    }

    if (messages === null) {
      setFlashMessageList([]);
    }
  }, [messages]);

  const handleRemoveFlash = (message) => {
    console.log('message: ', message);
    console.log('flashMessageList: ', flashMessageList);
    dispatch(popFlashMessage(message));
    setFlashMessageList(flashMessageList.filter((flash) => flash !== message));
  };

  return (
    !!flashMessageList.length && (
      <div className="wrapper">
        {flashMessageList.map((flashMessage) => (
          <Flash
            key={flashMessage.text}
            text={flashMessage.text}
            type={flashMessage.type}
            removable={flashMessage.removable}
            onRemove={() => handleRemoveFlash(flashMessage)}
            showIcon={flashMessage.showIcon}
          />
        ))}
      </div>
    )
  );
};

export default FlashMessages;
