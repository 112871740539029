import { useEffect } from 'react';

import { QueryStatus } from '@reduxjs/toolkit/query';
import { useSelector } from 'react-redux';

import {
  useCreateUserPrefsMutation,
  useGetUserPrefsQuery,
  userPreferencesDefaultState,
} from '@/api';
import { useAuth } from '@/contexts/AuthProvider';
import { error } from '@/lib/@datadog/browser-logs';
import { selectToken } from '@/store/authSlice';

/**
 * Custom hook for encapsulating "find or create" logic for user preferences.
 */
const useUserPreferences = () => {
  const { currentUser } = useAuth();
  const token = useSelector(selectToken);
  const [createUserPrefs, createMutRes] = useCreateUserPrefsMutation();

  const { data, status, isLoading, ...others } = useGetUserPrefsQuery(
    currentUser.id,
    {
      skip: !token,
    },
  );

  /**
   * Create a `user_preferences` entry in the database the first time a user loads the dashboard.
   */
  useEffect(() => {
    if (status !== QueryStatus.fulfilled) {
      return;
    }

    if (
      !createMutRes.error &&
      !data?.userPreference?.id &&
      createMutRes.isUninitialized
    ) {
      // debugger;
      createUserPrefs(userPreferencesDefaultState).catch((err: string) =>
        error(err),
      );
    }
  }, [isLoading, data, createMutRes, status, createUserPrefs]);

  return {
    data: data || userPreferencesDefaultState,
    status,
    isLoading,
    ...others,
  };
};

export default useUserPreferences;
