import { useMemo } from 'react';

import type { ROLES, UserPreferencesPages } from '@/types';
import type { RideColumn } from '../types';

import useUserPreferences from '@/hooks/useUserPreferences';

const useUserPreferredColumns = <T extends keyof typeof ROLES>(
  columns: RideColumn[],
  role: T,
  page: UserPreferencesPages[T],
) => {
  const {
    data: {
      userPreference: { dashboardColumnPreferences: columnPreferences },
    },
  } = useUserPreferences();

  const filteredColumns = useMemo(() => {
    return columns.filter((column) => {
      const hideKey = column?.hideKey;
      const excludedColumns =
        columnPreferences?.[role]?.[page]?.columnsToExcludeFromRendering;

      if (excludedColumns && hideKey) {
        return !Object.values(excludedColumns)?.includes(hideKey);
      }

      return true;
    });
  }, [columnPreferences, page, role, columns]);

  return filteredColumns;
};

export default useUserPreferredColumns;
