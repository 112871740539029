import * as React from 'react';

import type { Colors } from './types';

import * as Tooltip from '@/lib/@radix-ui/react-tooltip';

import InfoIcon from '../../../assets/images/icon-info.svg';

const Root = ({
  children,
  color,
}: {
  children: React.ReactNode;
  color?: Colors;
}) => (
  <div className={`status d-block relative no-wrap ${color}`}>{children}</div>
);

const Content = ({
  children,
  id,
}: {
  children: React.ReactNode;
  id: string;
}) => (
  <div className="status-message" data-testid={id}>
    {children}
  </div>
);

const StatusTooltip: React.FC<{
  children: React.ReactNode;
  onOpenChange?: (open: boolean) => void;
}> = ({ children, onOpenChange }) => (
  <Tooltip.Provider>
    <Tooltip.Root onOpenChange={onOpenChange}>
      <Tooltip.Trigger asChild>
        <div className="IconButton ml-1">
          <InfoIcon />
        </div>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <Tooltip.Content className="TooltipContent" sideOffset={5}>
          {children}
          <Tooltip.Arrow className="TooltipArrow" />
        </Tooltip.Content>
      </Tooltip.Portal>
    </Tooltip.Root>
  </Tooltip.Provider>
);

const Subtext = ({ children }: { children: React.ReactNode }) => (
  <div className="status-subtext" data-testid="status-subtext">
    {children}
  </div>
);

const RideStatusBadge = () => null;
RideStatusBadge.Root = Root;
RideStatusBadge.Content = Content;
RideStatusBadge.Tooltip = StatusTooltip;
RideStatusBadge.Subtext = Subtext;

export default RideStatusBadge;
