import * as React from 'react';

import * as AlertDialog from '@radix-ui/react-alert-dialog';

import './main.scss';

import CloseIcon from '../../../src/assets/images/Close.svg';

const Modal: React.FC<{
  children: React.ReactNode;
  hide: () => void;
  open: boolean;
  subtitle: string;
  title: string;
}> = ({ title, subtitle, children, open, hide }) => {
  return (
    <AlertDialog.Root open={open}>
      <AlertDialog.Portal>
        <AlertDialog.Overlay className="alert-dialog-overlay" />

        <AlertDialog.Content className="alert-dialog-content">
          <div className="modal-header">
            <AlertDialog.Title>{title}</AlertDialog.Title>
            <button type="button" className="close-button" onClick={hide}>
              <CloseIcon />
            </button>
          </div>
          <AlertDialog.Description>{subtitle}</AlertDialog.Description>
          {children}
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog.Root>
  );
};

export default Modal;
