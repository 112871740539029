/* eslint-disable import/prefer-default-export */

import type { Ride, RideEtaResponses } from '../../types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRideEtaResponses: build.query({
      query: (id: number) => {
        return {
          url: `/rides/${id}/ride_eta_responses`,
          method: 'GET',
        };
      },
      transformResponse: (response: { data: RideEtaResponses[] }) => {
        return (
          keysToCamel(response) as { rideEtaResponses: RideEtaResponses[] }
        ).rideEtaResponses;
      },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as { data: { errors: string[] } }, // TODO: Type error response
    }),
  }),
});

export const { useGetRideEtaResponsesQuery } = extendedApi;
