import React, { useState } from 'react';

import type { Ride, TransportationCompany } from '@/types';

import { useGetRideTransportationCompanyQuery } from '@/api/rides/getRideTransportationCompany';
import Loader from '@/components/Spinner/Spinner';
import RideStatusBadge from '@/features/RideStatusBadge';

const Claimed: React.FC<{ ride: Ride }> = ({ ride }) => {
  const [open, setOpen] = useState(false);
  const onOpenChange = (o: boolean) => setOpen(o);

  const {
    isLoading,
    isError,
    data: { transportationCompany } = {
      transportationCompany: {} as TransportationCompany,
    },
  } = useGetRideTransportationCompanyQuery(
    { rideId: ride.id, transportationCompanyId: ride.transportationCompanyId },
    {
      skip: !open,
    },
  );

  const showError = isError || (!isLoading && !transportationCompany.id);

  return (
    <RideStatusBadge.Tooltip onOpenChange={onOpenChange}>
      {isLoading && <Loader className="inline" size="small" />}
      {showError && <p>Some error occurred.</p>}

      {!isError && !isLoading && transportationCompany.id && (
        <p className="tooltip-text">
          Ride awarded to {transportationCompany.name}
        </p>
      )}
    </RideStatusBadge.Tooltip>
  );
};

export default Claimed;
