import type { AppDispatch } from '@/store';
import type { CcRideStatusBadges } from '@/types';

import { useDispatch, useSelector } from 'react-redux';

import useRideCount from '@/features/RidesTable/hooks/useRideCount';
import {
  resetFilters,
  selectRideFilters,
} from '@/features/RidesTable/store/cc/ridesFilterSlice';
import { CC_RIDE_STATUS_BADGES, DEFAULT_CC_FILTER_STATUSES } from '@/types';

import CalendarIcon from '../../../../assets/images/calendar-o.svg';
import ResetFilters from '../ResetFilters';

export const HEADERS = {
  scheduled: 'No scheduled rides within this date range',
  canceled: 'No canceled rides within this date range',
  completed: 'No completed rides within this date range',
};

const EmptyRidesTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { statusBadge, search, endTime, startTime, isInit } =
    useSelector(selectRideFilters);

  const completedIsActive =
    statusBadge.length === 1 &&
    statusBadge[0] === CC_RIDE_STATUS_BADGES.completed;

  const canceledIsActive =
    statusBadge.length === 1 &&
    statusBadge[0] === CC_RIDE_STATUS_BADGES.canceled;

  let text = HEADERS.scheduled;
  let statuses: { statusBadge: CcRideStatusBadges[] } = {
    statusBadge: DEFAULT_CC_FILTER_STATUSES,
  };

  if (canceledIsActive) {
    text = HEADERS.canceled;
    statuses = {
      statusBadge: [CC_RIDE_STATUS_BADGES.canceled],
    };
  } else if (completedIsActive) {
    text = HEADERS.completed;
    statuses = {
      statusBadge: [CC_RIDE_STATUS_BADGES.completed],
    };
  }

  const { count } = useRideCount(statuses, search, endTime, startTime);

  return (
    <div className="empty-state" id="no-rides-placeholder">
      <div className="empty-state-icon">
        <CalendarIcon />
      </div>

      <h2 className="empty-state-heading">{text}</h2>
      {!isInit && (
        <ResetFilters count={count} reset={() => dispatch(resetFilters())} />
      )}
    </div>
  );
};

export default EmptyRidesTable;
