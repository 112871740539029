import * as React from 'react';

import Filters from '@/features/RidesTable/components/Filters';

const ResetFilters: React.FC<{ count: number; reset: () => void }> = ({
  count,
  reset,
}) => {
  return (
    <span className="d-flex flex-row flex-justify-center">
      {/* TODO: Hide if count is 0? */}
      <p className="m-0">{count} rides hidden by filters.</p>
      {/* TODO: Hide if `isInit`? */}
      <Filters.Root>
        <Filters.ClearFilters visible reset={reset} />
      </Filters.Root>
    </span>
  );
};

export default ResetFilters;
