import type { CellComponent } from '@/features/RidesTable/types';

import LocationCell from './LocationCell';

const DropoffLocationCell: CellComponent = ({ ride, href }) => (
  <LocationCell
    location={ride.dropoffLocation}
    label="Drop-off"
    href={typeof href === 'function' ? href(ride) : href}
  />
);

export default DropoffLocationCell;
