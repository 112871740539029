// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-text {
  margin: unset;
  color: unset;
  color: '#fff';
  font-size: unset;
  font-size: '0.8125rem';
}
`, "",{"version":3,"sources":["webpack://./app/javascript/components/RidesTable/careCoordinator/tooltips/main.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB","sourcesContent":[".tooltip-text {\n  margin: unset;\n  color: unset;\n  color: '#fff';\n  font-size: unset;\n  font-size: '0.8125rem';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
