import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';

import type { Facet, RideBookerFacet } from '@/types';
import type { RideColumn, RideFilterState } from '../types';

import { useDispatch, useSelector } from 'react-redux';

import Pagination from '@/components/Pagination/Pagination';
import CcEmptyRidesTable from '@/components/RidesTable/careCoordinator/EmptyRidesTable';
import EmptyRidesTable from '@/components/RidesTable/EmptyRidesTable';
import ErrorPanel from '@/components/RidesTable/ErrorPanel';
import Spinner from '@/components/Spinner/Spinner';
import { useAuth } from '@/contexts/AuthProvider';
import Table from '@/features/RidesTable/components/Table';
import { isCommunity } from '@/path_defs';
import { selectToken } from '@/store/authSlice';
import { ROLES } from '@/types';

import useRides from '../hooks/useRides';
import TableProvider from '../providers/TableProvider';
import {
  setAllRidesSelected,
  setSelectedRides,
} from '../store/selectedRidesSlice';

const merge = <T extends Facet>(a: T[], b: T[]): T[] => {
  const c = [...a];
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) =>
    c.some((cItem) => bItem.id === cItem.id) ? null : c.push(bItem),
  );
  return c;
};

const shouldMerge = (a: Facet[], b: Facet[]) =>
  a.length !== b.length &&
  a.map(({ id }) => id).sort() !== b.map(({ id }) => id).sort();

const TableHandler: React.FC<{
  children: React.ReactNode;
  columns: RideColumn[];
  filters: RideFilterState;
  onChangeRowsPerPage: (selected: number) => void;
  onChangeSortField: (field: string) => void;
  onChangeSortType: (type: string | null) => void;
  rowsPerPage: number;
  setPage: (page: number) => void;
  sortField: string | null;
  sortType: string | null;
}> = ({
  columns = [],
  children,
  filters,
  onChangeRowsPerPage,
  rowsPerPage,
  setPage,
  sortType,
  onChangeSortType,
  sortField,
  onChangeSortField,
}) => {
  const { currentUser } = useAuth();
  const [hospitalList, setHospitalList] = useState<Facet[]>([]);
  const [rideBookers, setRideBookers] = useState<RideBookerFacet[]>([]);

  const {
    rides,
    isFetching,
    count,
    facets,
    getRides,
    isError,
    messages,
    removeRideById,
    selectAllRides,
    updateRideById,
  } = useRides(filters);

  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const deselectAllRides = useCallback(() => {
    dispatch(setSelectedRides([]));
    dispatch(setAllRidesSelected(false));
  }, [dispatch]);

  /**
   * Manually trigger API request when filters change.
   */
  useEffect(() => {
    if (token) {
      getRides(); // eslint-disable-line @typescript-eslint/no-floating-promises
    }
  }, [filters, token]);

  const tableKey = rides.map(({ id }) => id).join('-');

  useEffect(() => {
    if (
      facets?.hospitals?.length > 0 &&
      shouldMerge(hospitalList, facets.hospitals)
    ) {
      // Hospital list should aggregate all hospitals instead of listing the most recent API request values.
      setHospitalList(merge(hospitalList, facets.hospitals));
    }

    if (
      facets?.rideBookers?.length > 0 &&
      shouldMerge(rideBookers, facets.rideBookers)
    ) {
      // Hospital list should aggregate all hospitals instead of listing the most recent API request values.
      setRideBookers(merge(rideBookers, facets.rideBookers));
    }
  }, [facets.hospitals, facets.rideBookers, hospitalList, rideBookers, rides]);

  const ctx = useMemo(
    () => ({
      rides,
      updateRideById,
      removeRideById,
      hospitals: hospitalList,
      selectAllRides,
      deselectAllRides,
      onChangeRowsPerPage,
      sortType,
      onChangeSortType,
      sortField,
      onChangeSortField,
      rideBookers,
    }),
    [
      rides,
      updateRideById,
      removeRideById,
      hospitalList,
      selectAllRides,
      deselectAllRides,
      onChangeRowsPerPage,
      sortType,
      onChangeSortType,
      sortField,
      onChangeSortField,
      rideBookers,
    ],
  );

  return (
    <TableProvider ctx={ctx}>
      <div data-testid="react-dashboard-table">
        {children}

        {isFetching && <Spinner />}

        {rides.length > 0 && !isFetching && (
          <Table
            key={tableKey}
            rides={rides}
            type={isCommunity() ? 'community' : 'scheduled'}
            columns={columns}
          />
        )}

        {currentUser.role === ROLES.dispatcher &&
          rides.length === 0 &&
          !isFetching &&
          !isError && <EmptyRidesTable />}
        {(currentUser.role === ROLES.careCoordinator ||
          currentUser.role === ROLES.superUser) &&
          rides.length === 0 &&
          !isFetching &&
          !isError && <CcEmptyRidesTable />}

        {!isFetching && isError && <ErrorPanel messages={messages} />}

        {!isFetching && (
          <Pagination
            total={count}
            rowsPerPage={rowsPerPage}
            page={filters.page}
            setPage={setPage}
          />
        )}
      </div>
    </TableProvider>
  );
};

export default TableHandler;
