import * as Toast from '@/lib/@radix-ui/react-toast';

const handleViewRide = (rideId: number) => {
  window.location.href = `/dispatcher/ride/${rideId}`;
};

const ViewRideAction = (rideId: number) => (
  <Toast.Action className="ToastAction" asChild altText="Goto schedule to undo">
    <button
      type="button"
      className="Button small green"
      onClick={() => handleViewRide(rideId)}
    >
      View Ride
    </button>
  </Toast.Action>
);

export default ViewRideAction;
