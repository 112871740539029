/* eslint-disable import/prefer-default-export */

import type { TransportationCompany } from '../../types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getRideTransportationCompany: build.query({
      query: ({
        rideId,
        transportationCompanyId,
      }: {
        rideId: number;
        transportationCompanyId: number;
      }) => {
        return {
          url: `/rides/${rideId}/transportation_companies/${transportationCompanyId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: {
        transportationCompany: TransportationCompany;
      }) => {
        return keysToCamel(response) as {
          transportationCompany: TransportationCompany;
        };
      },
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as { data: { errors: string[] } }, // TODO: Type error response
    }),
  }),
});

export const { useGetRideTransportationCompanyQuery } = extendedApi;
