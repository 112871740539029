import { useSelector } from 'react-redux';

import {
  selectAssignedStatuses,
  selectAvailableStatuses,
} from '@/features/RidesTable/store/ridesFilterSlice';
import { isCommunity, isScheduled } from '@/path_defs';

import CalendarIcon from '../../../assets/images/calendar-o.svg';

const SCHEDULED_HEADER = 'No assigned rides within this date range';
const CANCELED_HEADER = 'No canceled rides within this date range';
const COMPLETED_HEADER = 'No completed rides within this date range';
const COMMUNITY_HEADER = 'No available rides within this date range';
const DECLINED_HEADER = 'No declined rides within this date range';

const EmptyRidesTable = () => {
  const assignedStatuses = useSelector(selectAssignedStatuses);
  const availableStatuses = useSelector(selectAvailableStatuses);

  let header = '';

  if (isCommunity()) {
    if (availableStatuses.includes('declined')) {
      header = DECLINED_HEADER;
    } else {
      header = COMMUNITY_HEADER;
    }
  }

  if (isScheduled()) {
    // TODO: Find better way to check what page we are on
    if (assignedStatuses.includes('completed')) {
      header = COMPLETED_HEADER;
    } else if (assignedStatuses.includes('canceled')) {
      header = CANCELED_HEADER;
    } else {
      header = SCHEDULED_HEADER;
    }
  }

  return (
    <div className="empty-state" id="no-rides-placeholder">
      <div className="empty-state-icon">
        <CalendarIcon />
      </div>

      <h2 className="empty-state-heading">{header}</h2>

      {/* TODO: Figure out how to make links "just work"™ in both ecosystems */}
      {isScheduled() && (
        <a
          href="/dispatcher/community"
          className="button -inverted-blue mt-2 -medium"
        >
          View Community
        </a>
      )}

      {/* TODO: Uncomment after launching enable-react-router */}
      {/*
       * Can't uncomment this until after launching FF. This component is mounted and unmounted
       * too frequently to attempt keeping track of past feature flag states.
       */}
      {/* {isScheduled() && !invalidRolePresent && (
        <Link
          to="/dispatcher/community"
          className="button -inverted-blue mt-2 -medium"
        >
          View Community
        </Link>
      )} */}
    </div>
  );
};

export default EmptyRidesTable;
