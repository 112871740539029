import { useState } from 'react';
import * as React from 'react';

const Search: React.FC<{
  search: string;
  setSearch: (search: string) => void;
}> = ({ search, setSearch }) => {
  const [inputSearchString, setInputSearchString] = useState(search);

  return (
    <>
      <div className="field -inline -full-width-sm my-1 my-sm-0">
        <input
          className="input -inline -filter -thick"
          type="text"
          name="search"
          placeholder="Name or Ride ID"
          onChange={(e) => setInputSearchString(e.target.value)}
          value={inputSearchString}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setSearch(inputSearchString);
            }
          }}
        />
      </div>

      <div className="field -inline -full-width-sm my-1 my-sm-0">
        <button
          className="button -search -block-sm"
          type="button"
          aria-label="Search rides"
          onClick={() => setSearch(inputSearchString)}
        />
      </div>
    </>
  );
};

export default Search;
