import type { UserColumnPreferences } from '@/types';

import { roundtripBaseApiV3 } from '@/lib/rtk-query';
import { keysToCamel } from '@/src/util/CaseConvert';

export const userPreferencesDefaultState: UserColumnPreferences = {
  userPreference: {
    dashboardColumnPreferences: {
      admin: {
        scheduled: {},
      },
      // eslint-disable-next-line camelcase
      care_coordinator: {
        scheduled: {},
      },
      dispatcher: {
        assigned: {},
        community: {},
      },
    },
  },
};

const extendedApi = roundtripBaseApiV3.injectEndpoints({
  endpoints: (build) => ({
    getUserPrefs: build.query({
      providesTags: ['UserPrefs'],
      query: (userId: number) => {
        return {
          url: `user_preferences/${userId}`,
          method: 'GET',
        };
      },
      transformResponse: (response: UserColumnPreferences) =>
        keysToCamel(response) as UserColumnPreferences,
      transformErrorResponse: (response: unknown) =>
        keysToCamel(response) as Record<string, unknown>, // TODO: Type error response
    }),
    createUserPrefs: build.mutation<void, Partial<UserColumnPreferences>>({
      invalidatesTags: ['UserPrefs'],
      query: (params) => ({
        url: '/user_preferences',
        method: 'POST',
        body: params,
      }),
    }),
    updateUserPrefs: build.mutation<
      void,
      {
        userId: number;
        userPreference: UserColumnPreferences['userPreference'];
      }
    >({
      invalidatesTags: ['UserPrefs'],
      query: (params) => {
        const { userId } = params;
        if (userId === undefined) {
          throw new Error('userId must be defined');
        }

        return {
          url: `user_preferences/${userId}`,
          method: 'PATCH',
          body: params,
        };
      },
    }),
  }),
});

export const {
  useGetUserPrefsQuery,
  useCreateUserPrefsMutation,
  useUpdateUserPrefsMutation,
} = extendedApi;
