/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';

import * as React from 'react';

import Close from '@assets/images/CloseXSmall.svg';

import { DeclineReasons } from '@/types';

import BulkDeclineModal from './BulkDeclineModal';

const BulkDeclineOption: React.FC<{ selectedRides: number[] }> = ({
  selectedRides,
}) => {
  const [showDeclineModal, setShowDeclineModal] = useState(false);

  return (
    <div className="">
      <span className="filter-label" onClick={() => setShowDeclineModal(true)}>
        Decline rides
        <Close alt="Close" />
      </span>
      <BulkDeclineModal
        hide={() => setShowDeclineModal(false)}
        show={showDeclineModal}
        rideIds={selectedRides}
      />
    </div>
  );
};

export default BulkDeclineOption;
