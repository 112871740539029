/* eslint-disable jsx-a11y/label-has-associated-control */
import { useEffect, useState } from 'react';

import * as React from 'react';

import type { Facet } from '@/types';

import InfoIcon from '@assets/images/icons/Info.svg';
import { ChevronDownIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import { useDispatch, useSelector } from 'react-redux';

import Separator from '@/components/Separator';
import * as Popover from '@/lib/@radix-ui/react-popover';
import * as Tooltip from '@/lib/@radix-ui/react-tooltip';
import isEmpty from '@/utils/isEmpty';

import { useTableContext } from '../../providers/TableProvider';
import { selectIsInit, setHospitals } from '../../store/ridesFilterSlice';
import CheckboxItem from './CheckboxItem';

const filterLabel = 'Requesting facility';

const getSelectedHospitals = (
  hospitalList: Facet[],
  hospitalIds: {
    [key: number]: number | null;
  },
) => hospitalList.filter(({ id }) => !hospitalIds[id]).map(({ id }) => id);

const getFilterLabel = (hospitalList: Facet[], selectedHospitals: number[]) => {
  if (selectedHospitals.length === hospitalList.length) {
    return filterLabel;
  }
  if (selectedHospitals.length === 1) {
    return `${filterLabel}: 1 facility`;
  }

  return `${filterLabel}: ${selectedHospitals.length} facilities`;
};

const RequestingFacility = () => {
  const dispatch = useDispatch();
  const isInit = useSelector(selectIsInit);
  const { hospitals } = useTableContext();

  // An object representing the DESELECTED hospitals
  // Selected hospitals are absent from the object or have a null value i.e. { 1: null }.
  const [hospitalIds, setHospitalIds] = useState<{
    [key: number]: number | null;
  }>({});

  const [hospitalList, setHospitalList] = useState(hospitals);
  const [hospitalSearchText, setHospitalSearchText] = useState('');

  // State to track if the input is focused
  const [isFocused, setIsFocused] = useState(false);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isInit) {
      setHospitalIds({});
    }
  }, [isInit]);

  useEffect(() => {
    setHospitalList(hospitals);
  }, [hospitals]);

  useEffect(() => {
    dispatch(setHospitals([]));
  }, [dispatch]);

  const handleApply = () => {
    const selectedHospitals = getSelectedHospitals(hospitalList, hospitalIds);

    /**
     * `ridesFilterSlice` checks if `hospitals` is an empty array when determining
     * the `isInit` value. If a user selects a checkbox item and then deselects it,
     * set the filter state to an empty array instead of the  full list.
     */
    const hosp =
      selectedHospitals.length === hospitalList.length ? [] : selectedHospitals;

    dispatch(setHospitals(hosp));
  };

  const handleCheck = (id: number) => {
    setHospitalIds({
      ...hospitalIds,
      [id]: hospitalIds[id] ? null : id,
    });
  };

  const filterHospitalList = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHospitalSearchText(event.target.value);
    if (
      event.target.value === null ||
      event.target.value === undefined ||
      event.target.value.trim() === ''
    ) {
      setHospitalList(hospitals);
    } else {
      setHospitalList(
        hospitals.filter((hospital) =>
          hospital.name
            .toLocaleUpperCase()
            .includes(event.target.value.toLocaleUpperCase()),
        ),
      );
    }
  };

  return (
    <div className="">
      <Popover.Root open={open} onOpenChange={setOpen}>
        <Popover.Trigger asChild>
          <span className="filter-label">
            {getFilterLabel(
              hospitalList,
              getSelectedHospitals(hospitalList, hospitalIds),
            )}
            <ChevronDownIcon />
          </span>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content className="popover-content py-3 px-0" align="start">
            <div className="popover-top d-flex px-4 flex-column mb-0">
              <label className="popover-title" htmlFor="facility-search">
                <div
                  className="d-flex flex-items-center"
                  style={{ gap: '0.25rem' }}
                >
                  Filter facilities
                  <Tooltip.Provider>
                    <Tooltip.Root>
                      <Tooltip.Trigger asChild>
                        <div className="IconButton">
                          <InfoIcon />
                        </div>
                      </Tooltip.Trigger>
                      <Tooltip.Portal>
                        <Tooltip.Content
                          className="TooltipContent"
                          sideOffset={2}
                        >
                          List displays facilities actively scheduling rides.
                          <Tooltip.Arrow className="TooltipArrow" />
                        </Tooltip.Content>
                      </Tooltip.Portal>
                    </Tooltip.Root>
                  </Tooltip.Provider>
                </div>
              </label>
              <div className={`styled-input ${isFocused ? 'focused' : ''}`}>
                <div className="d-flex pl-2">
                  <MagnifyingGlassIcon />
                </div>
                <div className="d-flex flex-1 flex-self-stretch">
                  <input
                    placeholder="Find by name"
                    className="styled-input__field"
                    id="facility-search"
                    onChange={filterHospitalList}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    value={hospitalSearchText}
                  />
                </div>
              </div>
            </div>
            <div className="popover-scrollist ">
              {hospitalList.length > 0 ? (
                <>
                  {/* Only show Select All when there are 2 or more hospitals */}
                  {hospitalList.length > 1 && (
                    <>
                      <div className="py-3 px-4">
                        <CheckboxItem
                          text="Select all"
                          key="select-all"
                          checked={isEmpty(hospitalIds)}
                          onCheckedChange={() => setHospitalIds({})}
                        />
                      </div>

                      <Separator />
                    </>
                  )}

                  <div className="py-3 px-4">
                    {hospitalList.map((hospital) => (
                      <CheckboxItem
                        text={hospital.name}
                        key={hospital.id}
                        onCheckedChange={() => handleCheck(hospital.id)}
                        checked={!hospitalIds[hospital.id]}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="empty-state py-3 py-4 -no-border">
                  No results found.
                </div>
              )}
            </div>

            <div className="popover-actions d-flex flex-justify-end px-4 mt-0">
              <button
                className="button -small -no-background"
                onClick={() => setOpen(false)}
                type="button"
              >
                Cancel
              </button>
              <button
                className="button -small -primary"
                onClick={handleApply}
                type="button"
              >
                Apply
              </button>
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default RequestingFacility;
